
const render = () => {
    import(/*webpackChunkName: 'init'*/"./init")
}

if ('startsWith' in String.prototype &&
    'endsWith' in String.prototype &&
    'includes' in Array.prototype &&
    'assign' in Object &&
    'keys' in Object &&
    'Promise' in window &&
    'fetch' in window) {
    render()
} else {
    let script = document.createElement("script")
    script.src = `${PUBLIC_PATH}polyfills.${POLYFILLS_HASH}.js`
    script.onload = () => render()
    document.body.appendChild(script)
}
